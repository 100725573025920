import React from 'react'
import { Box } from '@mui/material'

const allTitles = [
  '1.	Information We Collect',
  '2.	How We Use Your Information',
  '3.	Consent:',
  '4.	Data and Security',
  '5.	Data Retention',
  '6.	Your Rights',
  '7.	Data Sharing and Transfers',
  '8.	Cookies and Tracking Technologies',
  '9.	Changes to this Statement',
  '10.	Changes to This Privacy Policy',
  '11.	Contact Us',
  '12.	General information about cookies',
]

const point2 = [
  '•	We use the personal data we collect for the following purposes:',
  '•	To provide and improve our services.',
  '•	To process transactions and manage your orders.',
  '•	To communicate with you, including sending updates, marketing materials (with your consent), and responding to inquiries.',
  '•	To comply with legal and regulatory obligations.',
  '•	To enhance your experience on our website and improve our services.',
]

const sx = {
  pt: '120px',
  pb: '30px',
  px: ['20px', 0],
  h2: {
    mt: '20px',
  },
  '.dl': {
    my: '10px',
  },
  '.bold': {
    fontWeight: 'bold',
  },
  '.padding-12': {
    pl: '12px',
  },
}

export const UaeData = () => {
  return (
    <Box sx={sx}>
      <div>
        <b>Disclaimer</b>: This Privacy Policy is drafted according to UAE Data Protection Law
        (Federal Decree-Law No. 45 of 2021) and general data privacy principles:
      </div>
      <h2>Privacy Policy:</h2>
      <div>Last Updated: [12.11.2024]</div>
      <div className='dl'>
        With this privacy policy (hereinafter as “<b>Policy</b>”) we would like to inform you about
        how we process personal data during our business cooperation with you, your use of our
        services and your visit to our website{' '}
        <a href='https://www.imile.com/'>https://www.imile.com/</a>. The protection of your privacy
        is of the utmost importance to us, so compliance with the legal provisions on data
        protection is a matter of course for us.
      </div>
      <div>
        At iMile Delivery Services L.L.C ("we”), we respect and protect your privacy. This Privacy
        Policy explains how we collect, use, store, and protect your personal data in compliance
        with the UAE Data Protection Law (Federal Decree-Law No. 45 of 2021) and other applicable
        privacy regulations.
      </div>
      <div>
        This Statement of Privacy applies to iMile Delivery website and governs data collection and
        usage. By using the iMile Delivery Services L.L.C website, you consent to the data practices
        described in this statement.
      </div>
      <div className='bold dl'>This Policy describes: </div>
      {allTitles.map((item) => (
        <div className='bold'>{item}</div>
      ))}
      <div className='bold dl'>1. Information We Collect: </div>
      <div className='dl'>
        iMile Delivery Services L.L.C collects personally identifiable information, such as your
        e-mail address, name, home or work address or telephone number. iMile Delivery Services
        L.L.C also collects anonymous demographic information, which is not unique to you, such as
        your ZIP code, age, gender, preferences, interests, and favourites. There is also
        information about your computer hardware and software that is automatically collected by
        iMile Delivery Services L.L.C. This information can include: your IP address, browser type,
        domain names, access times and referring website addresses. This information is used by
        iMile Delivery Services L.L.C for the operation of the service, to maintain quality of the
        service, and to provide general statistics regarding use of the iMile Delivery Services
        L.L.C website.
      </div>
      <div className='dl'>
        Please keep in mind that if you directly disclose personally identifiable information or
        personally sensitive data through iMile Delivery Services L.L.C public message boards, this
        information may be collected and used by others.
      </div>
      <div className='dl'>
        <b>Note</b>: iMile Delivery Services L.L.C does not read any of your private online
        communications. iMile Delivery Services L.L.C encourages you to review the privacy
        statements of websites you choose to link to from iMile Delivery Services L.L.C so that you
        can understand how those websites collect, use, and share your information. iMile Delivery
        Services L.L.C is not responsible for the privacy statements or other content on websites
        outside of the iMile Delivery Services L.L.C.
      </div>
      <div className='bold dl'>Use of your Personal Information</div>
      <div className='dl'>
        iMile Delivery Services L.L.C collects and uses your personal information to operate the
        iMile Delivery Services L.L.C website and deliver the services you have requested. iMile
        Delivery Services L.L.C also uses your personally identifiable information to inform you of
        other products or services available from iMile Delivery Services L.L.C and its affiliates.
        iMile Delivery Services L.L.C may also contact you via surveys to conduct research about
        your opinion of current services or of potential new services that may be offered.
      </div>
      <div className='dl'>
        iMile Delivery Services L.L.C does not sell, rent, or lease its customer lists to third
        parties. iMile Delivery Services L.L.C may, from time to time, contact you on behalf of
        external business partners about a particular offering that may be of interest to you. To
        disable this function, you could contact us through our Contact Us page. In those cases,
        your unique personally identifiable information (e-mail, name, address, telephone number) is
        not transferred to the third party and iMile may keep from time to time log files containing
        information about your visit on our website such as (cookie IDs, IP addresses, advertising
        IDs) and Customer data (such as billing data, user profiles, address, order history, payment
        data).
      </div>
      <div className='dl'>
        In addition, iMile Delivery Services L.L.C may share data with trusted partners to help us
        perform statistical analysis, send you email or postal mail, provide customer support, or
        arrange for deliveries. All such third parties are prohibited from using your personal
        information except to provide these services to iMile Delivery L.L.C, and they are required
        to maintain the confidentiality of your information.
      </div>
      <div className='dl'>
        iMile Delivery Services L.L.C does not use or disclose sensitive personal information, such
        as race, religion, or political affiliations, without your explicit consent. iMile Delivery
        Services L.L.C keeps track of the websites and pages our customers visit within iMile
        Delivery Services L.L.C, to determine what iMile Delivery Services L.L.C services are the
        most popular. This data is used to deliver customized content and advertising within iMile
        Delivery Services L.L.C to customers whose behaviour indicates that they are interested in a
        particular subject area.
      </div>
      <div className='dl'>
        iMile Delivery Services L.L.C websites will disclose your personal information, without
        notice, only if required to do so by law or in the good faith belief that such action is
        necessary to: (a) conform to the edicts of the law or comply with legal process served on
        iMile Delivery Services L.L.C or the site; (b) protect and defend the rights or property of
        iMile Delivery Services L.L.C; and (c) act under exigent circumstances to protect the
        personal safety of users of iMile Delivery Services L.L.C, or the public.
      </div>
      <div className='bold dl'>2. How We Use Your Information </div>
      <div className='padding-12 bold'>
        {point2.map((item) => (
          <div>{item}</div>
        ))}
      </div>
      <div className='bold dl'>3. Consent: </div>
      <div className='dl'>
        By providing your personal data, you consent to its collection, use, and processing in
        accordance with this Privacy Policy. You may withdraw your consent at any time by contacting
        us as provided below.
      </div>
      <div className='bold dl'>4. Data and Security:</div>
      <div className='dl'>
        We implement industry-standard security measures to protect your personal data from
        unauthorized access, loss, or disclosure. However, please be aware that no method of
        transmission over the internet or method of electronic storage is 100% secure.
      </div>
      <div className='dl'>
        iMile Delivery Services L.L.C secures your personal information from unauthorized access,
        use, or disclosure. iMile Delivery Services L.L.C secures the personally identifiable
        information you provide on computer servers in a controlled, secure environment, protected
        from unauthorized access, use, or disclosure. When personal information (such as a credit
        card number) is transmitted to other websites, it is protected using encryption, such as the
        Secure Socket Layer (SSL) protocol.
      </div>
      <div className='bold dl'>5. Data Retention:</div>
      <div className='dl'>
        We retain your personal data only for as long as necessary to fulfil the purposes outlined
        in this Privacy Policy or to comply with legal or regulatory requirements.
      </div>
      <div className='bold dl'>6. Your Rights:</div>
      <div className='dl'>You have the right to:</div>
      <div>
        {[
          '•	Access and request a copy of your personal data.',
          '•	Correct any inaccuracies in your personal data.',
          '•	Request the deletion of your personal data, subject to applicable legal requirements.',
          '•	Object to or restrict the processing of your personal data.',
        ].map((item) => (
          <div>{item}</div>
        ))}
      </div>
      <div className='dl'>
        To exercise any of these rights, please contact us at DPO [at] imile.me.
      </div>
      <div className='dl bold'>7. Data Sharing and Transfers:</div>
      <div className='dl'>
        We do not sell, rent, or share your personal data with third parties for marketing purposes.
        We may share your personal data with third-party service providers who assist us in
        fulfilling services such as payment processing, delivery, and data storage, provided that
        they comply with applicable data protection laws.
      </div>
      <div className='dl'>
        If we transfer your personal data outside the UAE, we will ensure that adequate safeguards
        are in place to protect your information.
      </div>
      <div className='dl bold'>8. Cookies and Tracking Technologies: </div>
      <div className='dl'>
        Our website may use cookies or similar tracking technologies to improve user experience,
        analyze site traffic, and personalize content. You can manage your cookie preferences
        through your browser settings.
      </div>
      <div className='dl bold'>9. Changes to this Statement</div>
      <div className='dl'>
        iMile Delivery Services L.L.C will occasionally update this Statement of Privacy to reflect
        company and customer feedback. iMile Delivery Services L.L.C encourages you to periodically
        review this Statement to be informed of how iMile Delivery Services L.L.C is protecting your
        information.
      </div>
      <div className='dl bold'>10. Changes to This Privacy Policy:</div>
      <div className='dl'>
        We may update this Privacy Policy from time to time to reflect changes in our practices,
        legal requirements, or other operational reasons. We will notify you of any significant
        changes by posting the revised policy on our website.
      </div>
      <div className='dl bold'>11. Contact Us:</div>
      <div className='dl'>
        If you have any questions or concerns regarding this Privacy Policy or our data practices,
        please contact us at:
      </div>
      <div className='dl'>
        <b>Email</b>: DPO [at] imile.me.
      </div>
      <div className='dl'>
        <b>Phone</b>: +971600566221
      </div>
      <div className='dl'>
        <b>Address</b>: WH 01, PLOT NO 93-0
      </div>
      <div>
        {[
          'EUROSTAR COMMUNICATIONS BUILDING,',
          'DUBAI INVESTMENT PARK FIRST',
          'PO BOX- 454158',
          'DUBAI, UNITED ARAB EMIRATES',
          'iMile Delivery Services L.L.C welcomes your comments regarding this Statement of Privacy. If you believe that iMile Delivery Services L.L.C has not adhered to this Statement, please contact iMile Delivery Services L.L.C by its website “Contact Us” section. We will use commercially reasonable efforts to promptly determine and remedy the problem.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl bold'>12. General information about cookies</div>
      <div className='dl'>
        Cookies are data records that are stored in databases of the browser. For example, user
        identification numbers are stored here, which are transmitted to the user’s computer when
        the website is used and managed there. The data records are kept ready there for later
        access. Typical uses of cookies are, for example, language selection, consent documentation
        or authentication of the user.
      </div>
    </Box>
  )
}

export const UaeArData = () => {
  return (
    <Box
      sx={{
        ...sx,
        direction: 'rtl',
      }}
    >
      <div className='dl'>
        إخلاء المسؤولية: صيغت وثيقة سياسة الخصوصية الماثلة وفقًا لقانون حماية البيانات الشخصية في
        دولة الإمارات العربية المتحدة (المرسوم بقانون اتحادي رقم (45) لسنة 2021 بشأن حماية البيانات
        الشخصية) ومبادئ خصوصية البيانات العامة:
      </div>
      <h2>سياسة الخصوصية:</h2>
      <div className='dl'>آخر تحديث: [12/11/2024]</div>
      <div className='dl'>
        من خلال وثيقة سياسة الخصوصية الماثلة (المشار إليها فيما يلي باسم "السياسة") نود أن نطلعك على
        كيفية معالجتنا للبيانات الشخصية أثناء تعاوننا التجاري معك، واستخدامك للخدمات الخاصة بنا،
        وزيارتك لموقعنا الإلكتروني: <a href='https://www.imile.com'>https://www.imile.com</a> حيث
        تشكل حماية خصوصيتك أهمية قصوى بالنسبة لنا، لذا فإن الامتثال للأحكام القانونية المتعلقة
        بحماية البيانات أمر طبيعي بالنسبة لنا.
      </div>
      <div className='dl'>
        في شركة ايمايل للخدمات اللوجستية ("نحن")، نحترم ونحمي خصوصيتك. تشرح سياسة الخصوصية الماثلة
        كيفية جمع بياناتك الشخصية واستخدامها وتخزينها وحمايتها وفقًا لقانون حماية البيانات في دولة
        الإمارات العربية المتحدة (المرسوم بقانون اتحادي رقم (45) لسنة 2021 بشأن حماية البيانات
        الشخصية) واللوائح الأخرى المعمول بها فيما يتعلق بخصوصية البيانات.
      </div>
      <div className='dl'>
        تنطبق قوانين الخصوصية على الموقع الإلكتروني لشركة ايمايل للخدمات اللوجستية ويحكم جمع
        البيانات واستخدامها. باستخدام موقع شركة ايمايل للخدمات اللوجستية الإلكتروني، فإنك توافق على
        الممارسات الموضحة في هذه الوثيقة.
      </div>
      <div className='dl bold'>توضح هذه الوثيقة ما يلي:</div>
      <div>
        {[
          '1)	البيانات التي نجمعها.',
          '2)	كيف نستخدم بياناتك.',
          '3)	الموافقة.',
          '4)	البيانات والأمان.',
          '5)	حفظ البيانات الشخصية.',
          '6)	حقوقك.',
          '7)	مشاركة البيانات الشخصية ونقلها.',
          '8)	ملفات تعريف الارتباط وتكنولوجيا التتبع.',
          '9)	التعديلات على هذه الوثيقة.',
          '10)	التعديلات على سياسة الخصوصية الماثلة.',
          '11)	للتواصل معنا.',
          '12)	بيانات عامة حول ملفات تعريف الارتباط.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl bold'>1. البيانات التي نجمعها:</div>
      <div className='dl'>
        تجمع شركة ايمايل للخدمات اللوجستية بيانات التعريف الشخصية، مثل عنوان بريدك الإلكتروني أو
        اسمك أو عنوان منزلك أو عملك أو رقم هاتفك. كما تجمع شركة ايمايل للخدمات اللوجستية بيانات
        ديموغرافية مجهولة، ليست فريدة بالنسبة لك، مثل الرمز البريدي والعمر والجنس والتفضيلات
        والاهتمامات والمفضلات. هناك أيضًا بيانات حول أجهزة الكمبيوتر والبرامج الخاصة بك يتم جمعها
        تلقائيًا بواسطة شركة ايمايل للخدمات اللوجستية . يمكن أن تتضمن هذه البيانات: عنوان بروتوكول
        الإنترنت (IP) الخاص بك ونوع المتصفح وأسماء النطاقات وأوقات الوصول وعناوين مواقع الويب
        المرجعية. تستخدم شركة ايمايل للخدمات اللوجستية هذه البيانات لتشغيل الخدمة والحفاظ على جودة
        الخدمة وتقديم إحصاءات عامة بخصوص استخدام موقع شركة ايمايل للخدمات اللوجستية على الشبكة.
      </div>
      <div className='dl'>
        يرجى أن تضع في اعتبارك أنه إذا قمت بالكشف عن بيانات تعريف شخصية أو بيانات شخصية حساسة من
        خلال لوحات الرسائل العامة الخاصة بشركة ايمايل للخدمات اللوجستية، فقد يتم جمع هذه البيانات
        واستخدامها من قبل الآخرين.
      </div>
      <div className='dl'>
        <b>ملاحظة</b>: لا تقرأ شركة ايمايل للخدمات اللوجستية أيًا من اتصالاتك الخاصة عبر الإنترنت.
        تشجعك شركة ايمايل للخدمات اللوجستية على مراجعة بيانات الخصوصية الخاصة بمواقع الويب التي
        تختار الارتباط بها من شركة ايمايل للخدمات اللوجستية حتى تتمكن من فهم كيفية جمع هذه المواقع
        لبياناتك واستخدامها ومشاركتها. لا تتحمل شركة ايمايل للخدمات اللوجستية المسؤولية عن بيانات
        الخصوصية أو المحتوى الآخر على مواقع الويب خارج شركة ايمايل للخدمات اللوجستية .
      </div>
      <div className='dl bold'>استخدام بياناتك الشخصية</div>
      <div className='dl'>
        تقوم شركة ايمايل للخدمات اللوجستية بجمع بياناتك الشخصية واستخدامها لتشغيل موقع شركة ايمايل
        للخدمات اللوجستية على الويب وتقديم الخدمات التي طلبتها. كما تستخدم شركة ايمايل للخدمات
        اللوجستية بياناتك الشخصية لإبلاغك بالمنتجات أو الخدمات الأخرى المتوفرة لدى شركة ايمايل
        للخدمات اللوجستية والشركات التابعة لها. كما قد تتواصل معك شركة ايمايل للخدمات اللوجستية عبر
        استطلاعات الرأي لإجراء بحث حول رأيك في الخدمات الحالية أو الخدمات الجديدة المحتملة التي قد
        يتم تقديمها.
      </div>
      <div className='dl'>
        لا تبيع شركة ايمايل للخدمات اللوجستية قوائم عملائها أو تؤجرها أو تؤجرها لأطراف ثالثة. ربما
        تتواصل شركة ايمايل للخدمات اللوجستية معك من وقت لآخر نيابة عن شركاء أعمال خارجيين بشأن عرض
        معين قد يثير اهتمامك. لإيقاف هذه الخدمة، يمكنك الاتصال بنا من خلال صفحة "تواصل معنا". في هذه
        الحالات، لا يتم نقل بياناتك الشخصية الخاصة (البريد الإلكتروني، الاسم، العنوان، رقم الهاتف)
        إلى الطرف الثالث، وقد تحتفظ شركة ايمايل للخدمات اللوجستية بملفات السجل التي تحتوي على
        معلومات حول زيارتك لموقعنا الإلكتروني مثل (ملفات تعريف الارتباط، وعناوين بروتوكول الإنترنت
        (IP)، ملفات تعريف الإعلانات) وبيانات العملاء (مثل بيانات الفواتير، وملفات تعريف المستخدم،
        والعنوان، وسجل الطلبات، وبيانات الدفع).
      </div>
      <div className='dl'>
        بالإضافة إلى ذلك، قد تشارك شركة ايمايل للخدمات اللوجستية البيانات الشخصية مع شركاء موثوق بهم
        لمساعدتنا في إجراء تحليل إحصائي، أو إرسال بريد إلكتروني أو بريد عادي إليك، أو لتقديم الدعم
        للعملاء، أو ترتيب عمليات التسليم. يُحظر على جميع هذه الأطراف الثالثة استخدام بياناتك الشخصية
        باستثناء تقديم هذه الخدمات إلى شركة ايمايل للخدمات اللوجستية، ويتم تبليغهم بضرورة الحفاظ على
        سرية بياناتك الخاصة.
      </div>
      <div className='dl'>
        لا تستخدم شركة ايمايل للخدمات اللوجستية أو تكشف عن بيانات شخصية حساسة، مثل العرق أو الدين أو
        الانتماءات السياسية، دون الحصول على موافقة صريحة منك. تحتفظ شركة ايمايل للخدمات اللوجستية
        بسجل لمواقع الويب والصفحات التي يزورها عملاؤنا داخل شركة ايمايل للخدمات اللوجستية، لتحديد
        خدمات شركة ايمايل الأكثر شهرة. تُستخدم هذه البيانات الشخصية لتقديم محتوى وإعلانات مخصصة داخل
        شركة ايمايل للعملاء الذين يشير سلوكهم إلى اهتمامهم بمجال موضوع معين.
      </div>
      <div className='dl'>
        لن تكشف مواقع شركة ايمايل للخدمات اللوجستية عن بياناتك الشخصية، دون إشعار مسبق، إلا في حال
        كان القانون يتطلب ذلك، أو انطلاقاً من مبدأ حسن النية حيث أن مثل هذا الإجراء يكون ضرورياً من
        أجل: (أ) الامتثال لمبادئ القانون، أو الامتثال للإجراءات القانونية المقدمة إلى شركة ايمايل
        للخدمات اللوجستية أو الموقع الخاص بها؛ (ب) حماية والدفاع عن حقوق أو ممتلكات شركة ايمايل
        للخدمات اللوجستية ؛ و(ج) التصرف في ظل ظروف ملحة لحماية السلامة الشخصية لمستخدمي شركة ايمايل
        للخدمات اللوجستية أو الجمهور.
      </div>
      <div className='dl bold'>2. كيف نستخدم بياناتك</div>
      <div>
        {[
          '	نستخدم البيانات الشخصية التي نجمعها للأغراض التالية:',
          '	لتقديم خدماتنا وتحسينها.',
          '	لمعالجة المعاملات وإدارة الطلبات.',
          '	للتواصل معك، بما في ذلك لغرض إرسال التحديثات والمواد التسويقية (بموافقتك)، والرد على الاستفسارات.',
          '	للامتثال للالتزامات القانونية والتنظيمية.',
          '	لتحسين تجربتك على موقعنا الإلكتروني، وتطوير خدماتنا.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl bold'>3. الموافقة:</div>
      <div className='dl'>
        من خلال تقديم بياناتك الشخصية، فإنك توافق على جمعها واستخدامها ومعالجتها وفقًا لسياسة
        الخصوصية الماثلة. يمكنك سحب موافقتك في أي وقت عن طريق الاتصال بنا على النحو الموضح أدناه.
      </div>
      <div className='dl bold'>4. البيانات الشخصية والأمان:</div>
      <div className='dl'>
        نطبق تدابير أمنية قياسية في الصناعة لحماية بياناتك الشخصية من الوصول غير المصرح به أو
        الفقدان أو الكشف. ومع ذلك، يرجى العلم أنه لا توجد طريقة نقل عبر الإنترنت أو طريقة تخزين
        إلكتروني آمنة بنسبة 100٪
      </div>
      <div className='dl'>
        تحمي شركة ايمايل للخدمات اللوجستية بياناتك الشخصية من الوصول غير المصرح به أو الاستخدام أو
        الكشف عن البيانات الشخصية. تؤمن شركة ايمايل للخدمات اللوجستية البيانات الشخصية التي تقدمها
        على خوادم الكمبيوتر في بيئة آمنة خاضعة للرقابة ومحمية من الوصول أو الاستخدام أو الكشف غير
        المصرح به. عندما يتم تحويل البيانات الشخصية (مثل رقم بطاقة الائتمان) إلى مواقع ويب أخرى، يتم
        حمايتها باستخدام التشفير، مثل بروتوكول طبقة مآخذ التوصيل الآمنة (SSL) للاتصال الآمن بين
        جهازين أو تطبيقين على شبكة.
      </div>
      <div className='dl bold'>5. حفظ البيانات الشخصية:</div>
      <div className='dl'>
        نحتفظ ببياناتك الشخصية فقط طالما كان ذلك ضروريًا لتحقيق الأغراض الموضحة في سياسة الخصوصية
        الماثلة، أو لأعراض الامتثال للمتطلبات القانونية أو التنظيمية الأخرى.
      </div>
      <div className='dl bold'>6. حقوقك:</div>
      <div className='dl'>تملك الحق في:</div>
      <div>
        {[
          '	الوصول إلى بياناتك الشخصية وطلب نسخة منها.',
          '	تعديل أي أخطاء في بياناتك الشخصية.',
          '	طلب حذف بياناتك الشخصية، وفقًا للمتطلبات القانونية المعمول بها.',
          '	الاعتراض على معالجة بياناتك الشخصية أو تقييدها.',
        ].map((item) => (
          <div className='dl'>{item}</div>
        ))}
      </div>
      <div className='dl bold'>
        لممارسة أي من هذه الحقوق، يرجى الاتصال بنا على:{' '}
        <a href='mailto:DPO@imile.me'>DPO@imile.me</a>
      </div>
      <div className='dl bold'>7. مشاركة البيانات الشخصية ونقلها:</div>
      <div className='dl'>
        لا نبيع أو نؤجر أو نشارك بياناتك الشخصية مع أطراف ثالثة لأغراض التسويق. يجوز لنا مشاركة
        بياناتك الشخصية مع مقدمي خدمات تابعين لجهات خارجية يساعدوننا في تنفيذ بعض الخدمات، مثل
        معالجة الدفع والتسليم وتخزين البيانات الشخصية ، شريطة أن يمتثلوا لقوانين حماية البيانات
        الشخصية المعمول بها.
      </div>
      <div className='dl'>
        في حال تحويل بياناتك الشخصية خارج دولة الإمارات العربية المتحدة، فسوف نضمن وجود ضمانات كافية
        لحماية بياناتك.
      </div>
      <div className='dl bold'>8. ملفات تعريف الارتباط وتكنولوجيا التتبع:</div>
      <div className='dl'>
        قد يستخدم موقعنا ملفات تعريف الارتباط أو تكنولوجيا تتبع مماثلة لتحسين تجربة المستخدم وتحليل
        حركة المرور على الموقع وتخصيص المحتوى. يمكنك إدارة تفضيلات ملفات تعريف الارتباط من خلال
        إعدادات المتصفح.
      </div>
      <div className='dl bold'>9. التعديلات على هذه الوثيقة</div>
      <div className='dl'>
        تقوم شركة ايمايل للخدمات اللوجستية أحيانًا بتحديث وثيقة الخصوصية الماثلة ليعكس تعليقات
        الشركة والعملاء. تشجعك شركة ايمايل للخدمات اللوجستية على مراجعة هذه الوثيقة بشكل دوري لتكون
        على علم بكيفية قيام شركة ايمايل للخدمات اللوجستية بحماية بياناتك الخاصة.
      </div>
      <div className='dl bold'>10. التعديلات على سياسة الخصوصية الماثلة:</div>
      <div className='dl'>
        قد نقوم بتحديث سياسة الخصوصية الماثلة من وقت لآخر لتعكس التعديلات في ممارساتنا أو المتطلبات
        القانونية أو الأسباب التشغيلية الأخرى. وسوف نقوم بإخطارك بأي تغييرات كبيرة من خلال نشر
        السياسة المعدلة على موقعنا الإلكتروني.
      </div>
      <div className='dl bold'>11. اتصل بنا:</div>
      <div className='dl'>
        في حال كان لديك أي أسئلة أو مخاوف بشأن سياسة الخصوصية الماثلة أو ممارساتنا المتعلقة ببياناتك
        الخاصة، يرجى الاتصال بنا على:
      </div>
      <div className='dl'>
        <b>البريد الإلكتروني</b>: <a href='mailto:DPO@imile.me'>DPO@imile.me</a>
      </div>
      <div className='dl'>
        <b>هاتف رقم</b>: 00971600566221
      </div>
      <div className='dl'>
        <b>العنوان</b>: دبليو إتش 01،قطعة رقم 93 0
      </div>
      <div className='dl'>مبنى يوروستار للاتصالات ذ.م.م.</div>
      <div className='dl'>منطقة مجمع دبي للاستثمار 1.</div>
      <div className='dl'>صندوق بريد رقم: 454158.</div>
      <div className='dl'>إمارة دبي.</div>
      <div className='dl'>
        ترحب شركة ايمايل للخدمات اللوجستية بتعليقاتك بخصوص وثيقة الخصوصية الماثلة. إذا كنت تعتقد أن
        شركة ايمايل لم تلتزم بهذه الوثيقة، يرجى الاتصال بشركة ايمايل للخدمات اللوجستية من خلال قسم
        "اتصل بنا" من خلال الموقع الإلكتروني. نعدُ ببذل الجهود المعقولة تجاريًا لتحديد المشكلة
        ومعالجتها على الفور.
      </div>
      <div className='dl bold'>12. بيانات عامة حول ملفات تعريف الارتباط</div>
      <div className='dl'>
        ملفات تعريف الارتباط هي سجلات بيانات يتم تخزينها على قواعد بيانات المتصفح. على سبيل المثال،
        يتم تخزين أرقام تعريف المستخدم هنا، والتي يتم تحويلها إلى الحاسب الآلي الخاص بالمستخدم عند
        استخدام الموقع وإدارته. يتم الاحتفاظ بسجلات البيانات الشخصية الجاهزة للوصول إليها لاحقًا.
        الاستخدامات النموذجية لملفات تعريف الارتباط هي، على سبيل المثال، اختيار اللغة أو توثيق
        الموافقة أو مصادقة المستخدم.
      </div>
    </Box>
  )
}

import React, { useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import { graphql } from 'gatsby'
import { Box } from '@mui/material'
import { Header, Footer } from '@/components'
import {
  ItaContent,
  AusContent,
  enContent,
  plContent,
  getUrlParam,
  transCountry,
  NZLcontent,
  Espcontent,
} from '@/components/PrivacyPolicy/config'
import { UaeData, UaeArData } from '@/components/PrivacyPolicy/UaeEnContent'
import { PrivacyNoticeDetailDrawer } from '@/components/PrivacyNoticeDetailDrawer'

const PrivacyPolicy = (props) => {
  const [country, setCountry] = useState<string | undefined>('')
  const [lang, setLang] = useState<string | undefined>('')
  const footerLocale = JSON.parse(props.data.footerLocale.data)

  const { detailDrawerOpen, showDetailDrawer, detailDrawerContent } = PrivacyNoticeDetailDrawer({
    pageLocale: footerLocale,
  })

  useEffect(() => {
    const urlCountry =
      (getUrlParam('country') && transCountry[getUrlParam('country')]) || Cookies.get('COUNTRY')
    const urlLang = getUrlParam('language') || Cookies.get('EXPRESS_LANG')
    setCountry(urlCountry)
    setLang(urlLang)
  }, [])

  const showContent = useMemo(() => {
    let t: any = enContent

    if (country === 'Italy' && lang === 'it_IT') {
      t = ItaContent
    }
    if (country === 'Australia') {
      t = AusContent
    }
    if (country === 'Poland') {
      t = plContent
    }
    if (country === 'New Zealand') {
      t = NZLcontent
    }
    if (country === 'Spain') {
      t = Espcontent
    }
    return t
  }, [country, lang])

  const UAEshowContent = useMemo(() => {
    let t = <></>
    if (country === 'United Arab Emirates') {
      t = <UaeArData />
    }
    if (country === 'United Arab Emirates' && lang === 'en_US') {
      t = <UaeData />
    }
    return t
  }, [country, lang])

  const handleOpenUrl = (url: string) => {
    window.open(url)
  }

  const configContent = (
    <Box
      sx={{
        pt: '90px',
        padding: '90px 24px 60px',
        h1: {
          textAlign: 'center',
          p: '30px',
        },
        div: {
          m: '10px 0',
        },
        '.pl_20': {
          paddingLeft: '20px',
          m: '0',
        },
        '.m0': {
          m: '0',
        },
        '.mb_12': {
          marginBottom: '12px',
        },
        '.span_6': {
          padding: '0 6px',
        },
        '.bold': {
          fontWeight: '600',
        },
      }}
    >
      <h1>{showContent?.title}</h1>
      <div>
        <span>{showContent?.description}</span>
        <a onClick={() => handleOpenUrl(showContent?.url)} className='span_6'>
          {showContent?.url}.
        </a>
        <span>{showContent?.text}</span>
      </div>
      <div>{showContent?.questa?.title}</div>
      {showContent?.questa?.opts?.map((item, idx) => (
        <div className='pl_20'>{item}</div>
      ))}
      <div>
        <h3>{showContent?.content1?.title}</h3>
        {showContent?.content1?.opts?.map((item, idx) => (
          <div className='m0'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content2?.title}</h3>
        <div>{showContent?.content2?.desc}</div>
        {showContent?.content2?.opts?.map((item, idx) => (
          <div className='m0'>{item}</div>
        ))}
        <div>
          {showContent?.content2?.text}
          <a className='span_6' href={`mailto:${showContent?.content2?.email}`}>
            {showContent?.content2?.email}
          </a>
        </div>
      </div>
      <div>
        <h3>{showContent?.content3?.title}</h3>
        <div>{showContent?.content3?.desc}</div>
        {showContent?.content3?.opts?.map((item, idx) => (
          <div className='pl_20'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content4?.title}</h3>
        {showContent?.content4?.opts?.map((item, idx) => (
          <>
            <div className='m0 bold'>{item.key}</div>
            <div className='m0 mb_12'>{item.value}</div>
          </>
        ))}
        <div>{showContent?.content4?.p1}</div>
        <div>{showContent?.content4?.p2}</div>
        <div>
          {showContent?.content4?.p3}
          <a className='span_6' onClick={showDetailDrawer}>
            {showContent?.content4?.openDrawer}
          </a>
        </div>
      </div>
      <div>
        <h3>{showContent?.content5?.title}</h3>
        <div>{showContent?.content5?.desc}</div>
        {showContent?.content5?.opts?.map((item, idx) => (
          <div className='pl_20'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content6?.title}</h3>
        <div>{showContent?.content6?.desc}</div>
        {showContent?.content6?.opts?.map((item, idx) => (
          <div className='pl_20'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content7?.title}</h3>
        <div>{showContent?.content7?.desc}</div>
        {showContent?.content7?.opts?.map((item, idx) => (
          <div className='pl_20'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content8?.title}</h3>
        <div>{showContent?.content8?.desc}</div>
        {showContent?.content8?.opts?.map((item, idx) => (
          <div className='pl_20'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content9?.title}</h3>
        <div>{showContent?.content9?.desc}</div>
        {showContent?.content9?.opts?.map((item, idx) => (
          <div className='pl_20'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content10?.title}</h3>
        <div>{showContent?.content10?.desc}</div>
        {showContent?.content10?.opts?.map((item, idx) => (
          <div className='pl_20'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content11?.title}</h3>
        <div>{showContent?.content11?.desc}</div>
        {showContent?.content11?.opts?.map((item, idx) => (
          <div className='pl_20'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content12?.title}</h3>
        <div>{showContent?.content12?.desc}</div>
        {showContent?.content12?.opts?.map((item, idx) => (
          <div className='pl_20'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content13?.title}</h3>
        <div>{showContent?.content13?.desc}</div>
        {showContent?.content13?.opts?.map((item, idx) => (
          <div className='pl_20'>{item}</div>
        ))}
      </div>
      <div>
        <h3>{showContent?.content14?.title}</h3>
        <div>{showContent?.content14?.desc}</div>
      </div>
      <div>
        <h3>{showContent?.content15?.title}</h3>
        {showContent?.content15?.opts?.map((item, idx) => (
          <div className='mb_12'>{item}</div>
        ))}
      </div>
      {detailDrawerOpen && detailDrawerContent}
    </Box>
  )

  return (
    <>
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
      {country === 'United Arab Emirates' ? (
        <Box
          sx={{
            maxWidth: ['100%', '800px'],
            height: 'max-content',
            margin: '0 auto',
          }}
        >
          {UAEshowContent}
        </Box>
      ) : (
        configContent
      )}

      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export default PrivacyPolicy

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["footer"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    commonLocale: locale(ns: { eq: "common" }, language: { eq: $language }) {
      data
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
